import React from "react";
import classnames from 'classnames';


const aboutDetails = (onDetailClick) => ({
  "dev-manager": (
    <div className="card-text">
      <h6>Where I've been</h6>
      20+ years experience designing, developing, and managing various 
      software projects in both startup and enterprise environments.
      <ul>
        <li>Design Systems</li>
        <li>Data Visualizations</li>
        <li>Prototyping</li>
        <li>Custom Tools</li>
        <li>Mobile Development</li>
        <li>Dev Ops CI / CD</li>
        <li>Lean UX</li>
        <li>Agile</li>
      </ul>
      {/* <a onClick={onDetailClick} className="detail-link" href="#experience-map">see more...</a> */}
    </div>
  ),
  "creative-tech": (
    <div className="card-text">
      <h6>What I've learned</h6>
      Diverse experiences designing and developing fullstack applications has made me an expert designer-developer who
      focuses primarily on the UI and loves using React. For design I have evolved with the times. I used to use Adobe products exclusivly, but since have switched to using sketch and now I primarily use Figma.
      I have a deep understanding of UX and web accessibility standards and practices. 
      The last 6 years have been leading a team of creative-technologists in a large UX team in a giant regulated enterprise company. 
      I've built complex design systems using modern javascript development practices. 
      I've also led our team in designing and developing an assortment of custom built tools for various design problems.
    </div>
  ),
  "maker-builder": (
    <div className="card-text">
      <h6>Outside of tech</h6>
      Computers are great, but the limits of a screen sometimes fall flat. I also love to make &amp; build IRL.
      In the off hours many activities keep me busy. 
      Home improvements, golf, brewing beer, and spending time with my kids. Lo-fi activities keep me feeling balenced.
      {/* I've started to document the random side-projects. If you are interested you can check out my work <a href="blog.scotthealy.net" target="_blank" rel="noopener norefferer">here</a>. */}
    </div>
  ),
});

const About = ({area, forwardRef, onDetailAction, open, spread=false}) => {
  switch (area) {
    case "welcome":
      return (
        <div className="main-card-content" ref={forwardRef}>
          <div className="about-profile-main">
            <div className="about-profile-pic"></div>
          </div>
          <div className={classnames('about-profile-secondary', {'col': spread})}>
            <h1>Scott Healy</h1>
            <ul className="h2 about-list">
              <li>Designer<br></br>Developer <span className="about-ux">UX</span></li>
            </ul>
          </div>
        </div>
      );
    case "dev-manager":
      return (
        <div className="child-card-content" ref={forwardRef}>
          <h2>Dev / Design Manager</h2>
          <h3>Systems, automation, iteration</h3>
          <h4>Agile | CI / CD</h4>
          <div className="card-detail">
          {aboutDetails(onDetailAction)['dev-manager']}
          </div>
        </div>
      );
    case "creative-tech":
      return (
        <div className="child-card-content" ref={forwardRef}>
          <h2>Creative Technologist</h2>
          <h3>Building solutions for creation and colaboration</h3>
          <h4>React | CSS in JS | Data viz | A11y</h4>
          <div className="card-detail">
          {aboutDetails(onDetailAction)['creative-tech']}
          </div>
        </div>
      );
    case "maker-builder":
      return (
        <div className="child-card-content" ref={forwardRef}>
          <h2>Maker / Builder</h2>
          <h3>DIY</h3>
          <h4>Building | beer | bread</h4>
          <div className="card-detail">
          {aboutDetails(onDetailAction)['maker-builder']}
          </div>
        </div>
      );
    default:
      return <div ref={forwardRef}>:)</div>;
  }
};

export default About;
