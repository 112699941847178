import React, { useState, useRef, useEffect } from "react";
import {
  animated,
  useSpring,
  useSprings
} from "react-spring";
import "./App.css";
import About from "./About";
import Card from "./card";

const cardSizes = {
    height: 200,
    width: 300,
    margin: 60
};

const tTime = 500;

const colorPairs = [
  { a:'#dcdcdc',  b:'#fffaf0'},
  { a:'#F2F1F1',  b:'#FFE9E9'},
  { a:'#EDEEEE',  b:'#EAF6FF'},
  { a:'#FAFAF9',  b:'#F0FFE1'},
];

function App() {
  const [on, setOn] = useState(false);
  const [isSpread, setIsSpread] = useState(false);
  const [centeredPosition, setCenteredPosition] = useState(0);
  const [currentStep, setCurrentStep] = useState("");
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  const manRef = useRef<HTMLDivElement>(null);
  const ctRef = useRef<HTMLDivElement>(null);
  const mbRef = useRef<HTMLDivElement>(null);

  useEffect(()=>{
    const wH = window.innerHeight;
    const top = (wH / 2) - 75;
    setCenteredPosition(top);
  }, []);

  const refs = [
    null,
    manRef.current,
    ctRef.current,
    mbRef.current
  ]

  const getActiveItemHeight = (index=0) => {
    const currentRef:HTMLDivElement|null = refs && refs[index];
    return currentRef ? currentRef.clientHeight + 160 : 0
  }

  const handleDetailAction = (e:Event) => {
    e.preventDefault && e.preventDefault();
    console.log(e)
  }
  const items = [
    {
      content: <About area="welcome" forwardRef={manRef} spread={isSpread} open={on && currentStep === "0"}  onDetailAction={handleDetailAction}/>,
    },
    {
      content: <About area="dev-manager" forwardRef={manRef} spread={isSpread} open={currentStep === "1"} onDetailAction={handleDetailAction}/>,
    },
    {
      content: <About area="creative-tech" forwardRef={ctRef} spread={isSpread} open={currentStep === "2"} onDetailAction={handleDetailAction}/>,
    },
    {
      content: <About area="maker-builder" forwardRef={mbRef} spread={isSpread} open={currentStep === "3"} onDetailAction={handleDetailAction}/>,
    },
  ];

  const floatRef: any = useRef();
  const float = useSpring<any>({
    ref: floatRef,
    from: { transform: "translateY(0px)" },
    to: { transform: isSpread ? `translateY(${-cardSizes.height}px)` : `translateY(0px)` },
    config: { tension: 250 },
  });

  const spreadSprings = useSprings(
    items.length,
    items.map((item, index) => ({
      from: {
        transform: "translateY(0px)",
        height: `${cardSizes.height}px`
      },
      to: {
        height: index && activeCardIndex === index ? `${getActiveItemHeight(index)}px` : `${cardSizes.height}px`, 
        transform: isSpread
          ? `translateY(${activeCardIndex && activeCardIndex < index ? cardSizes.height * (index-1) + getActiveItemHeight(activeCardIndex) : cardSizes.height * index}px)`
          : `translateY(0px)`,
      },
    }))
  );
  const startingBg1 = '#dcdcdc';
  const startingBg2 = '#fffaf0';
  
  const backdropStyle = useSpring({
    from: { background:  `radial-gradient(circle, ${startingBg1},  ${startingBg2})` },
    to: { backgroundImage: on && !isNaN(activeCardIndex) ? `radial-gradient(${colorPairs[activeCardIndex].a}, ${colorPairs[activeCardIndex].b})` :  `radial-gradient(circle, ${startingBg1},  ${startingBg2})` }
  });

  const springTop = useSpring<any>({
    from: { transform: `translateY(${centeredPosition}px)` },
    to: { transform: on ? `translateY(20px)` : `translateY(${centeredPosition}px)` },
    config: { tension: 250 },
  });
 
  const handleClick = (event: Event) => {
    const buttonIndex:string | null = (event.currentTarget  as HTMLButtonElement).getAttribute('data-index');
    setCurrentStep(buttonIndex||"0");
    handleCardAction(buttonIndex);
  }

  const toggleCardSpread = (open:Boolean) => {
    if(!open) {
      setOn(true);
      setTimeout(()=>{
        setIsSpread(true);
      }, tTime);
    } else {
      setIsSpread(false);
      setTimeout(()=>{
        setOn(false);
      }, tTime);
    }
  };

  const handleCardAction = (buttonIndex:string | null) => {
    let rootEl = document.getElementById('root');
    if(rootEl) {
      rootEl.className = `step${buttonIndex}`;
    }
    const nextButtonIndex = parseInt(buttonIndex || '0');
    setActiveCardIndex(nextButtonIndex === activeCardIndex ? 0 : nextButtonIndex);
    switch(buttonIndex) {
      case "0":
        toggleCardSpread(on);
        break;
      case "1":
        break;
      case "2":
        break;
      case "3":
        break;
      default:
        break;
    }
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    if(event.key === 'Enter'){
      const buttonIndex:string | null = (event.target  as HTMLButtonElement).getAttribute('data-index');
      handleCardAction(buttonIndex);
    }
  }

  const getPageHeight = () => {
    const activeHeight = activeCardIndex ? getActiveItemHeight(activeCardIndex) : cardSizes.height;
    const height = on ? (spreadSprings.length-1) * cardSizes.height + activeHeight + cardSizes.margin : window.innerHeight
    return { height }; 
  }

  return (
    <div className="home-body" style={getPageHeight()}>
      <animated.div className="dynamic-backdrop" style={backdropStyle}></animated.div>
      <animated.div className="vertical-middle" style={springTop}>
        {spreadSprings.map((animation: React.CSSProperties | undefined, index) => (
          <animated.div
            className={`flip-card flip-card-${index} ${index && activeCardIndex === index ? 'card-active' : null}`}
            key={`${index}`}
            style={{ ...float, ...animation, zIndex: 999 - index }}
          >
            <Card onClick={handleClick} data-index={`${index}`} tabIndex="0" onKeyPress={handleKeyPress}>
              {items[index]
                ? (items[index]?.content as JSX.Element | undefined)
                : null}
            </Card>
          </animated.div>
        ))}
       
      </animated.div>
    </div>
  );
}

export default App;
